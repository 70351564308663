.navigation {
    background-color: #04628b;
    padding: 1%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.logo {
    height: 79px;
    width: 359px;
}

.nav {
    display: flex;
    margin-right: 3rem;
}

.nav__item {
    position: relative;
    margin-left: 2rem;
    font-size: 18px;
}

.nav__item a {
    text-decoration: none;
    color: white;
    padding: 0.6rem;
    transition: color 0.3s ease;
}

.nav__item:hover .dropdown {
    display: block;
}

.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: white;
    border-radius: 0.2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); 
}

.dropdown a {
    color: black;
    padding: 0.5rem; 
    font-size: 1rem; 
    display: block; 
    border-bottom: 1px solid #d8d8d8; 
}

.dropdown a:last-child {
    border-bottom: none; 
}

.phone__nav {
    display: none;
}

.menu__btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.menu__btn__burger {
    width: 25px;
    height: 3px;
    background: white;
    margin: 4px 0;
    transition: transform 0.5s;
}

.navbar {
    display: flex;
    flex-direction: column;
    background-color: #04628b;
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: height 0.3s ease;
    overflow: hidden;
}

.nav__links {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.nav__links a {
    color: white;
    padding: 0.8rem 0;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.nav__links a:last-child {
    border-bottom: none;
}

@media screen and (max-width: 823px) {
    .phone__nav {
        display: block;
    }
    .nav {
        display: none;
    }
}

@media screen and (max-width: 1025px) {
    .nav .dropdown {
        display: none;
    }
}
