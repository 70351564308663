  section.parallax {
    position: relative;
    height: 100vh;
    background: url("../Assets/Gallery/parallax.JPG") no-repeat center center/cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .parallax-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  section.parallax .container {
    position: relative;
    z-index: 1;
    color: white;
    font-size: 5em;
    text-align: center;
    padding: 0 20px;
  }
  
  section.gallery {
    padding: 50px 0;
    background: #f0f4f8;
    width: 100%;
  }
  
  section.gallery h2 {
    margin-bottom: 30px;
    font-size: 3em;
    color: #333;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .scrolling-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px; 
    padding: 0 10px;
  }
  
  .scrolling-images .card {
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s; 
    flex: 1 1 calc(25% - 20px); 
    max-width: calc(25% - 20px);
    cursor: pointer; 
  }
  
  .scrolling-images .card img {
    width: 100%;
    height: 200px; 
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .scrolling-images .card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); 
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-content {
    margin: auto;
    display: block;
    max-width: 80%;
    max-height: 80%;
    width: auto;
    height: auto;
    margin-top: 90px;
  }
  
  .close,
  .prev,
  .next {
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    color: #f1f1f1;
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;
  }
  
  .close {
    right: 25px;
    top: 10px;
  }
  
  .prev {
    left: 0;
    top: 50%;
  }
  
  .next {
    right: 0;
    top: 50%;
  }
  
  .close:hover,
  .prev:hover,
  .next:hover {
    color: #bbb;
  }
  
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
  }
  
  .hidden-image {
    display: none;
  }
  
  .contributors {
    padding: 50px 20px;
    background-color: #f0f4f8; 
  }
  
  .contributors .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .contributors h1 {
    margin-bottom: 30px;
    font-size: 2.5em;
    color: #333;
  }
  
  .slider-container {
    position: relative;
  }
  
  .slider {
    overflow: hidden;
    width: 100%;
  }
  
  .contributors-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    transition: transform 0.5s ease-in-out;
  }
  
  .contributor-card {
    width: calc(25% - 20px);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: transform 0.3s;
  }
  
  .contributor-card:hover {
    transform: translateY(-10px);
  }
  
  .contributor-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .contributor-name {
    font-size: 1.5em;
    margin: 10px 0 5px;
    color: #333;
  }
  
  .contributor-role {
    font-size: 1em;
    color: #777;
    margin-bottom: 15px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .social-icon {
    color: #777;
    font-size: 1.2em;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #007bff;
  }
  
  .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .left-btn {
    left: 0;
  }
  
  .right-btn {
    right: 0;
  }
  
  #search-box {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    text-align: center;
    border-radius: 20px; 
    border: 1px solid #ccc; 
  }

  
    
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    .scrolling-images .card {
      flex: 1 1 calc(50% - 20px); 
      max-width: calc(50% - 20px);
    }
  
    .contributor-card {
      width: calc(50% - 20px);
      height: auto; 
    }
  
    #search-box {
      width: 150px;
      border-radius: 20px;
      text-align: center;
    }
  }
  
  
  @media (max-width: 480px) {
    .scrolling-images .card {
      flex: 1 1 calc(100% - 20px);
      max-width: calc(100% - 20px);
    }
  
    .contributor-card {
      width: calc(100% - 20px); 
      height: auto; 
    }
  
    section.parallax .container {
      font-size: 3em;
      padding: 0 10px;
    }
  
    section.gallery h2 {
      font-size: 1.5em;
    }
  }
  