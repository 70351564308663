#hero {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  #hero .image_div {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .hero video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .hero__title {
    font-size: 4rem;
    letter-spacing: 1rem;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .events__section {
    width: 100%;
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    background-color: #e2e1e1;
  }
  
  .event {
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto 5rem;
    flex-wrap: wrap;
  }
  
  .event__image {
    width: 40%;
    max-width: 600px;
    box-shadow: 0 0 10px black;
    border-radius: 0.5rem;
  }
  
  #technical-img {
    width: 300px;
    height: 300px;
  }
  
  .event__div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  
  .inner__content {
    text-align: center;
    color: #2965b6;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .event__title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .event__description {
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .event__overview {
    letter-spacing: 0.5px;
    text-decoration: none;
    color: white;
    padding: 0.5rem 1rem;
    background-color: #ffa700;
    border-radius: 3px;
  }
  
  .event__overview:hover {
    background-color: #009acf !important;
  }
  
  @media screen and (max-width: 736px) {
    .hero {
      height: 250px;
    }
  
    .hero__title {
      font-size: 2.5rem;
    }

    .hero video{
        margin-top: 80px;
    }
   
    .events__section {
      padding: 8% 2%;
    }
  
    .event__image {
      width: 90%;
      height: auto;
    }
  
    .event__div, .event {
      width: 100%;
      padding: 0;
    }
  
    .event__description {
      text-align: justify;
      text-align-last: center;
      padding: 1%;
    }
  
    #technical-img {
      margin-right: 0;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .event {
      flex-direction: column;
      align-items: center;
      margin-bottom: 3rem;
    }
  
    .event__image {
      width: 80%;
      margin-bottom: 2rem;
    }
  
    .second {
      flex-direction: column-reverse;
    }
  }
  
  @media screen and (min-width: 1300px) and (max-width: 1400px) {
    .event {
      padding-right: 2%;
    }
  
    .second {
      padding-left: 2%;
    }
  }
  