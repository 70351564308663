#cards {
    background-color: #e9e9e9;
    padding: 5%;
  }
  
  #cards .cards-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -5rem 0 0 -5rem;
  }
  
  #cards .cards-content .event-card {
    overflow: hidden;
    width: 21rem;
    border-radius: 8px;
    margin: 5rem 0 0 5rem;
  }
  
  #cards .cards-content .event-card .event-img img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
  }
  
  #cards .cards-content .event-card .card-details {
    background-color: white;
    padding: 10%;
    height: 100%;
  }
  
  #cards .cards-content .event-card .card-details h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  #cards .cards-content .event-card .card-details p {
    text-align: justify;
    margin-bottom: 2rem;
    line-height: 1.4rem;
  }
  
  #cards .cards-content .event-card .card-details a {
    padding: 2.9% 10%;
    width: 29%;
    text-align: center;
    text-decoration: none;
    background-color: #009acf;
    font-size: 1.1rem;
    color: white;
    letter-spacing: 1px;
    border-radius: 5px;
  }

  .main {
    color: rgb(26, 0, 57);
    width: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-top)), color-stop(25%, var(--gradient-color-bottom)), to(var(--gradient-color-bottom)));
    background: linear-gradient(var(--gradient-color-top) 0%, var(--gradient-color-bottom) 25%, var(--gradient-color-bottom) 100%);
    padding: 5% 12%;
  }
  
  .mainContent {
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
  }
  
  .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5% 0% 0%;
  }
  
  .heading {
    font-size: 3.5rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }
  
  .register {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    letter-spacing: 0.5px;
    background-color: #009acf;
    border: none;
  }
  
  .register + .register {
    margin-left: 1.5rem;
  }
  
  .main_links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .main_links .brochure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .main_links .brochure a {
    text-decoration: none;
    color: white;
  }
  
  .main_links .brochure:hover{
    background-color: tomato;
  }
  
  .register:hover{
    background-color: tomato;
  }
  
  .main_links .download {
    margin-left: 0.5rem;
    width: 15px;
    height: 15px;
  }
  
  .aboutTitle,
  .detailsTitle,
  .contactsTitle {
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: 1.5px;
    margin-top: 50px;
    font-family: "Montserrat", sans-serif;
  }
  
  .aboutContent,
  .contactsContent {
    font-weight: 200;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin-top: 1rem;
    margin-left: 4%;
    font-family: "Roboto Condensed", sans-serif;
  }
  
  .contactsContent a {
    text-decoration: none;
    color: black;
  }
  
  .roundsTitle {
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 30px;
    font-family: "Montserrat", sans-serif;
  }
  
  .webListContent {
    margin-left: 4%;
  }
  
  .listsContent {
    text-indent: 1rem;
  }
  
  .roundsContent {
    font-weight: 200;
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.8rem;
    margin-top: 20px;
    margin-left: 4%;
    font-family: "Roboto Condensed", sans-serif;
  }
  
  .roundsContent li {
    margin-bottom: 1rem;
  }
  
  .roundsContent p {
    margin-bottom: 0.5rem;
  }
  
  .roundsContent h3 {
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  
  .iot {
    margin-top: 5rem;
  }
  
  @media only screen and (max-width: 1024px) {
    .main {
      padding: 8% 7%;
      margin-top: 70px;
    }
    .title {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
    .heading {
      font-size: 2.2rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    .aboutTitle,
    .detailsTitle,
    .contactsTitle {
      font-size: 1.3rem;
      letter-spacing: 1.5px;
    }
    .aboutContent,
    .contactsContent {
      font-size: 1rem;
      margin-left: 0;
    }
    .roundsTitle {
      font-size: 1.1rem;
    }
    .listsContent {
      margin-left: 5%;
      text-indent: 0;
    }
    .roundsContent {
      font-size: 1rem;
      margin-left: 0;
    }
    .themes {
      margin-left: 2rem;
    }
  }
  
  @media only screen and (max-width: 639px) {
    .main {
      padding: 15% 7%;
    }
  }
  
  @media screen and (max-width: 568px) {
    #hero {
      height: 33vh;
    }
    #cards {
      padding: 15% 5% !important;
    }
    .cards-content {
      margin: -4rem 0 0 -4rem !important;
    }
    .cards-content .event-card {
      width: 270px !important;
      margin: 4rem 0 0 4rem !important;
    }
    .cards-content .event-card .card-details {
      padding: 8% 10% !important;
    }
    .cards-content .event-card .card-details h4 {
      font-size: 1.5rem;
      margin-bottom: 0.6rem !important;
    }
    .cards-content .event-card .card-details p {
      margin-bottom: 1rem !important;
      line-height: 1.1rem !important;
      font-size: 0.8rem;
    }
    .cards-content .event-card .card-details a {
      padding: 2.5% 6% !important;
      font-size: .9rem !important;
    }
  }
  
  @media screen and (min-width: 569px) and (max-width: 750px) {
    #cards {
      padding: 8% 5% !important;
    }
    .cards-content {
      margin: -4rem 0 0 -4rem !important;
    }
    .cards-content .event-card {
      width: 250px !important;
      margin: 4rem 0 0 4rem !important;
    }
    .cards-content .event-card .card-details {
      padding: 6% 10% !important;
    }
    .cards-content .event-card .card-details h4 {
      font-size: 1.5rem;
      margin-bottom: 0.6rem !important;
    }
    .cards-content .event-card .card-details p {
      margin-bottom: 1rem !important;
      line-height: 1.1rem !important;
      font-size: 0.8rem;
    }
    .cards-content .event-card .card-details a {
      width: 25% !important;
      padding: 3% 6% !important;
      font-size: .9rem !important;
    }
  }
  
  @media screen and (min-width: 751px) and (max-width: 1025px) {
    #hero {
      height: 500px;
    }
    #cards {
      padding: 8% 5% !important;
    }
    .cards-content {
      margin: -4rem 0 0 -4rem !important;
    }
    .cards-content .event-card {
      width: 250px !important;
      margin: 4rem 0 0 4rem !important;
    }
    .cards-content .event-card .card-details {
      padding: 7% !important;
    }
    .cards-content .event-card .card-details h4 {
      font-size: 1rem !important;
      margin-bottom: 0.5rem !important;
    }
    .cards-content .event-card .card-details p {
      margin-bottom: 1rem !important;
      line-height: 1.1rem !important;
      font-size: 0.8rem;
    }
    .cards-content .event-card .card-details a {
      width: 25% !important;
      padding: 3% 6% !important;
      font-size: .9rem !important;
    }
  }
  